import React from "react";
import styled from "styled-components";
import SVG from "./SVG";

const Svg = styled(SVG)`
  width: auto;
  height: 2rem;
  color: ${({ theme }) => theme.colors.background};
`;

export const ImageCollection = (props) => {
	return (
		<Svg viewBox="0 0 31 30" {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M5.54555 27.2724C5.40883 27.2724 5.29429 27.3861 5.31785 27.5208C5.5643 28.9295 6.79353 30.0002 8.27284 30.0002H27.5001C29.1569 30.0002 30.5001 28.6571 30.5001 27.0003V7.77302C30.5001 6.29371 29.4294 5.06448 28.0206 4.81803C27.8863 4.79452 27.7728 4.90876 27.7728 5.04515V24.2724C27.7728 25.9292 26.4296 27.2724 24.7728 27.2724H5.54555Z" fill="currentColor" />
			<rect x="0.500061" width="25.2272" height="25.2272" rx="3" fill="currentColor" />
		</Svg>
	);
};
