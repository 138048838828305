import React from 'react';
import styled from "styled-components";
import AltImage from './AltImage';
import Text from './editorial/Text';
import Spacer from './layout/Spacer';
import { ImageCollection } from '../_svg/ImageCollection';

const Comp = styled.figure`

`

const ImageContainer = styled.a`
    display: block;
    aspect-ratio: ${p => p.ratio};
    overflow: hidden;
    width: 100%;
    height: auto;
    position: relative;
`

const CollectionIcon = styled(ImageCollection)`
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 2;
    height: 1.875rem;
    width: auto;
`

const TheImage = styled(AltImage)`

`

const Caption = styled(Text)`
    width: 100%;
`
function truncate(str, n, useWordBoundary) {
    if (str.length <= n) { return str; }
    const subString = str.slice(0, n - 1); // the original check
    return (useWordBoundary
        ? subString.slice(0, subString.lastIndexOf(" "))
        : subString) + "...";
};

const GalleryItem = ({ data }) => {
    const width = data.images?.[0].image.asset.gatsbyImageData.width
    const height = data.images?.[0].image.asset.gatsbyImageData.height

    const ratio = width > height ? 1.777777777777778 : 1

    console.log(data.images?.[0].image.asset.gatsbyImageData.width)
    return (
        <Comp>
            <ImageContainer ratio={ratio} href={`/photo/${data._id}`}>
                <TheImage data={data.images?.[0]} />
                {data.images.length > 1 ? <CollectionIcon /> : null}
            </ImageContainer>
            <Spacer s='small' />

            <Caption s="medium">
                {truncate(data.description, 75, ' ')}
            </Caption>
        </Comp>
    );
};

export default GalleryItem;